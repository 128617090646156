export const c = [
    {   
    name: '杀毒安全',
    icon: 'Tools',
    emoji: '💻',
    links: [
        { "name": "360安全卫士", "url": "https://www.360.cn/", "description": "360安全卫士是一款安全软件，提供多种杀毒功能和防病毒功能" },
        { "name": "TightVNC", "url": "https://www.tightvnc.com/", "description": "TightVNC 是一个跨平台的远程桌面控制软件，支持Windows和Linux操作系统。" },
        { "name": "Veracrypt", "url": "https://veracrypt.fr/en/Home.html", "description": "Veracrypt 是一个开源的密码管理工具，用于加密和保护数据。" },
        { "name": "VirtualBox", "url": "https://www.virtualbox.org/", "description": "VirtualBox 是一个开源的虚拟化软件，用于在虚拟机中运行操作系统。" },
        { "name": "Norton", "url": "https://www.norton.com/", "description": "全球知名的杀毒软件品牌，提供全面的网络安全保护、病毒扫描和防护" },
        { "name": "McAfee", "url": "https://www.mcafee.com/", "description": "提供防病毒、防间谍软件、网络安全保护的综合安全软件解决方案" },
        { "name": "Bitdefender", "url": "https://www.bitdefender.com/", "description": "领先的网络安全公司，提供强大的防病毒、恶意软件防护和隐私保护" },
        { "name": "Kaspersky", "url": "https://www.kaspersky.com/", "description": "全球知名的杀毒软件供应商，提供高级的病毒防护、反间谍和恶意软件扫描" },
        { "name": "Trend Micro", "url": "https://www.trendmicro.com/", "description": "提供专业的防病毒、防间谍软件和互联网安全服务，保护用户的设备免受攻击" },
        { "name": "AVG", "url": "https://www.avg.com/", "description": "提供免费的杀毒软件、恶意软件防护以及系统优化工具" },
        { "name": "Avast", "url": "https://www.avast.com/", "description": "全球领先的网络安全公司，提供防病毒、恶意软件防护、互联网安全及隐私保护功能" },
        { "name": "ESET", "url": "https://www.eset.com/", "description": "提供强大的病毒防护软件，涵盖企业级网络安全和个人设备的安全防护" },
        { "name": "Sophos", "url": "https://www.sophos.com/", "description": "专注于企业级网络安全的公司，提供防病毒、防勒索软件、网络防火墙等解决方案" },
        { "name": "Panda Security", "url": "https://www.pandasecurity.com/", "description": "西班牙的网络安全公司，提供防病毒软件、网络安全保护以及数据隐私解决方案" },
        { "name": "Virustotal", "url": "https://www.virustotal.com/", "description": "全球最大的病毒数据库，提供实时的病毒扫描和防护" },
        { "name": "VirusScan", "url": "https://www.virscan.org/", "description": "全球最大的病毒数据库，提供实时的病毒扫描和防护" },
    
    ]

    },



    {
    name: '文档编辑',
    icon: 'OfficeBuilding',
    emoji: '🏢',
    links: [
        { name: 'WPS', url: 'https://www.wps.cn/', description: 'WPS' },
        { name: 'Office', url: 'https://www.office.com/', description: '微软Office' },
        { name: 'LibreOffice', url: 'https://www.libreoffice.org/', description: '开源Office' },
        { name: 'Google Docs', url: 'https://docs.google.com/', description: '谷歌文档' },
        { name: 'Everything', url: 'https://www.voidtools.com/zh-cn/', description: '基于名称快速定位文件和文件夹。'},
        { name: 'AnyTxt', url: 'https://anytxt.net/', description: '拥有强大的最佳全文搜索引擎的桌面搜索工具。Google桌面搜索替代品。'},
        { name: 'KeePassXC', url: 'https://keepassxc.org/', description: 'KeePassXC 是一个开源的密码管理器，它支持多种加密算法，包括 AES、Twofish 和 Scrypt。' },
        { name: 'GitBook', url: 'https://www.gitbook.com/', description: 'GitBook 是一个开源的电子书编辑器，支持 Markdown 和 HTML 格式。' },
        { name: 'WinSCP', url: 'https://winscp.net/eng/index.php', description: 'WinSCP 是一个开源的远程文件管理工具，支持多种协议，包括 FTP、SFTP、SCP、HTTP 和 FTPS。' },
        { name: 'SmallPDF', url: 'https://smallpdf.com/', description: 'SmallPDF 是一个开源的 PDF 编辑器，支持多种格式，包括 PDF、EPUB、MOBI 和 CBZ。' }
    ]
    },

    {
        name: '漫画',
        icon: 'OfficeBuilding',
        emoji: '🏢',
        links: [
            { "name": "Bilibili Comics", "url": "https://manga.bilibili.com/", "description": "哔哩哔哩漫画平台，提供大量日本漫画和原创漫画，涵盖多种类别，支持在线阅读。" },
            { "name": "Webtoon", "url": "https://www.webtoons.com/", "description": "全球知名的在线漫画平台，提供各种类型的韩国漫画和原创作品，支持多语言阅读。" },
            { "name": "MangaDex", "url": "https://mangadex.org/", "description": "一个社区驱动的漫画阅读平台，提供多种语言版本的漫画，涵盖各种类别和类型。" },
            { "name": "MangaReader", "url": "https://www.mangareader.net/", "description": "提供大量日本漫画资源，用户可以在线阅读并支持多种漫画分类，更新及时。" },
            { "name": "ComiXology", "url": "https://www.comixology.com/", "description": "提供丰富的欧美漫画资源，拥有大量的DC、Marvel漫画以及其他独立漫画，部分内容免费。" },
            { "name": "Crunchyroll Manga", "url": "https://www.crunchyroll.com/comics/manga", "description": "由Crunchyroll提供的漫画平台，提供丰富的日本漫画资源，支持订阅和按章节阅读。" },
            { "name": "Tappytoon", "url": "https://www.tappytoon.com/", "description": "一个漫画平台，专注于韩国漫画（Webtoon），提供包括爱情、幻想、冒险等类别的免费和付费漫画。" },
            { "name": "KissManga", "url": "https://kissmanga.com/", "description": "提供大量日本漫画和动漫，资源更新频繁，支持按漫画类别浏览和在线阅读。" },
            { "name": "VIZ Media", "url": "https://www.viz.com/", "description": "VIZ Media是提供正版日本漫画的官方平台，提供大量热门漫画，如《火影忍者》、《海贼王》等。" },
            { "name": "Manganelo", "url": "https://manganelo.com/", "description": "一个免费提供各种日本漫画的平台，漫画更新及时，支持在线阅读和用户评论功能。" },
            { "name": "Lezhin Comics", "url": "https://www.lezhin.com/en", "description": "韩国漫画平台，提供大量独家原创漫画，特别是在爱情、BL、奇幻等类型的作品。" },
            { "name": "Toonily", "url": "https://www.toonily.com/", "description": "一个在线漫画平台，提供免费的韩国漫画，内容涵盖多个领域，更新较为频繁。" },
            { "name": "ManhuaScan", "url": "https://www.manhuascan.com/", "description": "提供丰富的中文漫画资源，包括经典和最新的日本、韩国及中国本土漫画，支持在线阅读。" },
            { "name": "ComicWalker", "url": "https://comic-walker.com/", "description": "由KADOKAWA推出的漫画平台，提供大量正版日本漫画，涵盖各种类型，支持多平台在线阅读。" },
            { "name": "JustDubs", "url": "https://www.justdubs.tv/", "description": "提供漫画与动漫的免费在线阅读，专注于日本动漫以及漫画的英文版。" },
            { "name": "Tapas", "url": "https://tapas.io/", "description": "提供原创网络漫画和小说的平台，涵盖多种题材，既有欧美漫画，也有亚洲风格的漫画，支持读者互动。" },
            { "name": "WebComics", "url": "https://www.webcomicsapp.com/", "description": "提供免费漫画和原创作品的平台，涉及各种类型的漫画，如爱情、动作、科幻等，适合各类漫画爱好者。" },
            { "name": "MangaRock", "url": "https://mangarock.com/", "description": "提供大量的日本漫画资源，并且支持漫画在线阅读和自定义标签，用户可以收藏和分享漫画。" },
            { "name": "Digital Comic Museum", "url": "https://digitalcomicmuseum.com/", "description": "一个免费提供欧美漫画的数字博物馆，收录了大量的公共领域的经典漫画。" },
            { "name": "DriveThruComics", "url": "https://www.drivethrucomics.com/", "description": "提供独立漫画作者的作品，用户可以在线购买或下载免费漫画，平台内容丰富多样。" },
            { "name": "Zingbox Comics", "url": "https://zingboxcomics.com/", "description": "提供大量免费网络漫画，专注于不同类型的漫画，用户可以免费观看并互动。" }
          ]
        },


    {
        name: '系统工具',
        icon: 'Tools',
        emoji: '🛠️',
        links: [
            { name: 'V2Ray',url:'https://www.v2ray.com/',description:'V2Ray'},
            { name: 'Shadowsocks',url:'https://shadowsocks.org/',description:'Shadowsocks'},
            { name: 'Tunnel',url:'https://tunnel.sh/',description:'Tunnel'},
            { name: 'Hiddify',url:'https://hiddify.com/',description:'Hiddify'},
            { name: 'V2RayNG',url:'https://github.com/2dust/v2rayNG/',description:'V2RayNG'},
            { name: 'ClashMeta',url:'https://github.com/MetaCubeX/ClashMetaForAndroid',description:'ClashMeta'},
            { name: '图吧工具箱',url:'https://www.tbtool.cn/',description:'集成大量常见硬件检测、评分工具，一键下载、方便使用'},
            { name: 'yt-dlp',url:'https://github.com/yt-dlp/yt-dlp',description:'yt-dlp'},
            {"name": "quicker","url": "https://getquicker.net/","description": "Quicker是一个“超级”启动器，它不仅可以帮助您打开软件或文件，更重要的是可以启动各类“操作”。为“操作”建立捷径，让效率触手可及！"},
            { name: 'OpenVPN',url:'https://openvpn.net/',description:'OpenVPN'},
            { name: 'Putty',url:'https://www.putty.org/',description:'ssh连接工具'},
            { name: 'Xshell',url:'https://www.xshell.com/',description:'远程连接工具'},
            {"name": "鲁大师","url": "https://www.ludashi.com/","description": "鲁大师"},
            {"name": "帮小忙","url": "https://tool.browser.qq.com/","description": "腾讯出品工具集合"},
        ]   
    },
 
]