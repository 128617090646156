export const devTools = [
  {
    name: 'Web 开发',
    icon: 'EditPen',
    emoji: '💻',
    links: [
      { name: 'GitHub', url: 'https://github.com', description: '全球最大的代码托管平台，支持 Git 版本控制和协作开发。' },
      { name: 'Github今日热门 ', url: 'https://github.com/trending', icon: 'ChatDotRound', description: 'Github今日热门' },
      { name: 'Github本月热门', url: 'https://github.com/trending?since=monthly', icon: 'ChatDotRound', description: 'Github今日热门' },
      { name: 'Gitee', url: 'https://gitee.com/', description: 'Gitee' },
      { name: 'Stack Overflow', url: 'https://stackoverflow.com/', description: 'Stack Overflow' },
      { name: 'LeetCode', url: 'https://leetcode.cn/', description: '力扣' },
      { name: 'CodePen', url: 'https://codepen.io/', description: 'CodePen 是一个面向前端设计师和开发人员的社交开发环境。构建和部署网站，展示您的工作，构建测试用例以学习和调试，并寻找灵感。' },
      { name: 'HackMD', url: 'https://hackmd.io/', description: '即時合作寫 Markdown' },
      { name: 'Hexo', url: 'https://hexo.io/', description: '快速、简洁且高效的博客框架' },
      { name: 'Hugo', url: 'https://gohugo.io/', description: 'Hugo 是一个静态网站生成器，它使用 Markdown 或 HTML 来构建网站。' },
      { name: 'MarkText', url: 'https://github.com/marktext/marktext', description: 'MarkText 是一个 Markdown 编辑器，它使用 Vue.js 和 Electron 构建。' },
      { name: 'Typora', url: 'https://github.com/typora/typora-issues', description: 'Typora 是一个 Markdown 编辑器，它使用 Vue.js 和 Electron 构建。'},
      { name: '7zip', url: 'https://www.7-zip.org/', description: '7zip 是一个文件压缩软件，它支持多个压缩格式，包括 ZIP、RAR、TAR、GZIP、BZIP2 等。' },
      { name: 'XAMPP', url: 'https://www.apachefriends.org/', description: 'Apache + MariaDB + PHP + Perl' },
      { name: 'WAMP', url: 'https://www.wampserver.com/', description: 'Apache + MariaDB + PHP + Perl' },
      { name: '宝塔', url: 'https://www.bt.cn/', description: '安全高效的服务器运维面板' },
      { name: 'phpBB', url: 'https://www.phpbb.com/', description: 'phpBB 是一个开源的论坛软件，支持多种语言和主题。' },
      { name: 'Docker', url: 'https://www.docker.com/', description: 'Docker 是一个开源的容器编排工具，它允许我们使用容器来构建、部署和运行应用程序。' },
      { name: 'Nginx', url: 'https://nginx.org/', description: 'Nginx 是一个高性能的 HTTP 和 reverse proxy 服务器，它支持负载均衡、SSL 加密、反向代理等特性。' },
      { name: 'Apache', url: 'https://httpd.apache.org/', description: 'Apache HTTP 服务器' },
      { name: 'Tomcat', url: 'https://tomcat.apache.org/', description: 'Apache Tomcat 是一个开源的 Java 应用程序服务器。' },
      { name: 'Redis', url: 'https://redis.io/', description: 'Redis 是一个开源的键值对数据库，它支持多种数据类型，如字符串、哈希、列表、集合等。' },
      { name: 'Elasticsearch', url: 'https://www.elastic.co/', description: 'Elasticsearch 是一个分布式的搜索和数据分析引擎。' },
      { name: 'Kafka', url: 'https://kafka.apache.org/', description: 'Kafka 是一个开源的分布式消息系统。' },
      { name: 'Kubernetes', url: 'https://kubernetes.io/', description: 'Kubernetes 是一个开源的分布式容器编排平台。' },
      { name: 'WordPress', url: 'https://wordpress.org/', description: 'WordPress 是一个开源的内容管理系统（CMS）' },
      { name: 'Joomla', url: 'https://www.joomla.org/', description: 'Joomla 是一个开源的内容管理系统（CMS）' },
      { name: 'GitLab', url: '  https://gitlab.com/', description: 'GitLab是一个开源端到端软件开发平台，具有内置版本控制、问题跟踪、代码审查、CI/CD 等功能。' },
      { name: 'Django', url: 'https://www.djangoproject.com/', description: 'Django 是一个高级的 Python 框架，用于快速开发 Web 应用程序。' },
      { name: 'Drupal', url: 'https://www.drupal.org/', description: 'Drupal 是一个开源的内容管理系统（CMS）' },
      { name: 'Laravel', url: 'https://laravel.com/', description: 'Laravel 是一个开源的 PHP 框架，用于快速开发高性能的 Web 应用程序。' },
      { name: 'Symfony', url: 'https://symfony.com/', description: 'Symfony 是一个开源的 PHP 框架，用于快速开发高性能的 Web 应用程序。' },
      { name: 'CakePHP', url: 'https://cakephp.org/', description: 'CakePHP 是一个开源的 PHP 框架，用于快速开发高性能的 Web 应用程序。' },
      { name: 'Ghost', url: 'https://ghost.org/', description: 'Ghost 是一个开源的博客平台，支持 Markdown 和 HTML。' },
      { name: 'Nextcloud', url: 'https://nextcloud.com/', description: 'Nextcloud 是一个开源的云存储和协作平台。' },
      { name: 'Cryptomator', url: 'https://cryptomator.org/', description: 'Cryptomator 是一个开源的加密存储和协作平台。' },
      { name: 'Discourse', url: 'https://www.discourse.org/', description: 'Discourse 是一个开源的社区平台，支持论坛和社区软件。' },
    ]
  },
  {
    name: '云服务器',
    icon: 'Cloudy',
    emoji: '☁️',
    links: [
      { name: 'GW云洞', url: 'https://www.gw-yundong.net/', description: 'VPS服务提供商' },
      { name: '优云666', url: 'https://youyun520.com/', description: 'VPS服务提供商' },
      { name: '飞机云', url: 'https://feijicloud.com/', description: 'VPS服务提供商' },
      { name: 'Vultr', url: 'https://www.vultr.com/', description: 'VPS服务提供商' },
      { name: 'Linode', url: 'https://www.linode.com/', description: 'VPS服务提供商' },
      { name: 'DigitalOcean', url: 'https://www.digitalocean.com/', description: 'VPS服务提供商' },
      { name: 'AWS', url: 'https://aws.amazon.com/', description: 'VPS服务提供商' },
      { name: 'Google Drive', url: 'https://drive.google.com/', description: 'Google云存储' },
      { name: 'OneDrive', url: 'https://onedrive.live.com/', description: '微软云存储' },
      { name: 'Cloudflare', url: 'https://www.cloudflare.com/', description: '云存储服务' },
      { name: 'Vercel', url: 'https://vercel.com/', description: '云平台' },
      { name: 'Netlify', url: 'https://www.netlify.com/', description: '云平台' },
      { name: 'Render', url: 'https://render.com/', description: '云平台' },
      { name: 'godaddy', url: 'https://www.godaddy.com/', description: '域名服务商' },
      { name: 'bandwagonhost', url: 'https://bandwagonhost.com/', description: 'VPS服务商' },
      { name: '优云666', url: 'https://youyun9.net/auth/login', description: 'VPS服务商' },
      { name: '腾讯云服务器',url:'https://cloud.tencent.com/',description:'腾讯云服务器'},
      { name: '阿里云服务器',url:'https://www.aliyun.com/',description:'阿里云服务器'},
      { name: '百度智能云',url:'https://cloud.baidu.com/',description:'百度云服务器'},
      { name: '华为云服务器',url:'https://www.huaweicloud.com/',description:'华为云服务器'},
      { name: 'oracle cloud',url:'https://www.oracle.com/cloud/',description:'甲骨文云服务'},
      { name: '西部数据',url:'https://www.west.cn/',description:'西部数据云服务'},
    ]
  },

  {
    name: '飞机场',
    icon: 'Cloudy',
    emoji: '☁️',
    links: [
      { name: 'GW云洞', url: 'https://www.gw-yundong.net/', description: 'VPS服务提供商' },
      { name: '优云666', url: 'https://youyun520.com/', description: 'VPS服务提供商' },
      { name: '飞机云', url: 'https://feijicloud.com/', description: 'VPS服务提供商' },
      { name: 'Vultr', url: 'https://www.vultr.com/', description: 'VPS服务提供商' },
      { name: '梯子导航', url: 'https://tzdaohang.com/', description: '梯子导航' },
    ]
  },

  {
    name: '壁纸美化',
    icon: 'Picture',
    emoji: '🖼️',
    links: [
      // 壁纸资源
      { name: 'Wallhaven', url: 'https://wallhaven.cc/', description: '高清壁纸网站' },
      { name: 'Unsplash', url: 'https://unsplash.com/', description: '免费图片素材' },
      { name: '致美化', url: 'https://zhutix.com/', description: '系统美化、壁纸、主题' },
    ]
  },
];
