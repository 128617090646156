export const b = [
    {   
    name: '笔记工具',
    icon: 'Tools',
    emoji: '✍️',
    links: [
        
        { name: '有道笔记', url: 'https://note.youdao.com/', description: '有道笔记' },
        { name: '印象笔记', url: 'https://www.yinxiang.com/', description: '印象笔记' },
        { name: 'Obsidian', url: 'https://obsidian.md/', description: 'Obsidian' },
        { name: '飞书', url: 'https://www.feishu.cn/', description: '飞书' },
        { name: 'Notion', url: 'https://www.notion.so/', description: 'Notion' },
        { name: '腾讯文档', url: 'https://docs.qq.com/', description: '腾讯文档' },
        { name: 'Joplin', url: 'https://joplinapp.org/', description: 'Joplin 是一款开源笔记应用程序。捕捉您的想法并从任何设备安全地访问它们。' },
    ]
    },
    {   
        name: '思维导图',
        icon: 'Tools',
        emoji: '🗺️',
        links: [
            { name: 'MindNode', url: 'https://www.mindnode.com/', description: 'MindNode' },
            { name: 'MindManager', url: 'https://www.mindmanager.com/', description: 'MindManager' },
            { name: 'MindMup', url: 'https://www.mindmup.com/', description: 'MindMup' },
            { name: 'Xmind', url: 'https://xmind.app/', description: 'Xmind' },
            { name: 'draw.io', url: 'https://app.diagrams.net/', description: '思维导图' },
           
        ]
    },

    {   
      name: '漏洞赏金平台',
      icon: 'Tools',
      emoji: '⛪',
      links: [
        {"name": "HackerOne", "url": "https://www.hackerone.com/", "description": "漏洞赏金和网络安全平台"},
        {"name": "Bugcrowd", "url": "https://www.bugcrowd.com/", "description": "漏洞赏金和安全测试平台"},
        {"name": "漏洞盒子", "url": "https://www.vulbox.com/", "description": "漏洞赏金和安全测试平台"},
        {"name": "synack", "url": "https://www.synack.com/", "description": "漏洞赏金和网络安全平台"}, 
      ]
   },

   {   
    name: '漏洞扫描',
    icon: 'Tools',
    emoji: '🔭',
    links: [
      {"name": "Shodan", "url": "https://www.shodan.io/", "description": "互联网连接设备搜索引擎"},
      {"name": "Nmap", "url": "https://nmap.org/", "description": "开源网络扫描工具"},
      {"name": "Censys", "url": "https://censys.io/", "description": "网络资产扫描和搜索引擎"},
      {"name": "Metasploit", "url": "https://www.metasploit.com/", "description": "渗透测试框架和安全研究工具"},
      {"name": "ParamSpider", "url": "https://github.com/devanshbatham/ParamSpider", "description": "从网络档案的黑暗角落挖掘 URL，以进行错误搜寻/模糊测试/进一步探测"},
      {"name": "Burp Suite", "url": "https://portswigger.net/burp", "description": "网络安全测试工具"},

    ]
  },

  {   
    name: 'Hacker Learn',
    icon: 'Tools',
    emoji: '🔭',
    links: [
      {"name": "Hack The Box", "url": "https://www.hackthebox.com/", "description": "网络安全练习和挑战平台"},
      {"name": "TryHackMe", "url": "https://tryhackme.com/", "description": "面向初学者的网络安全学习平台"},
      {"name": "PentesterLab", "url": "https://pentesterlab.com/", "description": "在线安全学习平台"},
    ]
  },


    {
        "name": "Hacker",
        "icon": "Tools",
        "emoji": "🩻",
        "links": [
          {"name": "Exploit Database", "url": "https://www.exploit-db.com/", "description": "安全漏洞和利用库"},
          {"name": "OWASP", "url": "https://owasp.org/", "description": "开源Web应用安全项目"},
          {"name": "CTFtime", "url": "https://ctftime.org/", "description": "CTF比赛和网络安全挑战信息"},
          {"name": "CyberSec Resources", "url": "https://cybersec.org/", "description": "网络安全工具和资源集合"},
          {"name": "Kali Linux", "url": "https://www.kali.org/", "description": "渗透测试和安全审计操作系统"},
          {"name": "SecurityFocus", "url": "https://www.securityfocus.com/", "description": "安全漏洞数据库和论坛"},
          {"name": "Reddit / r/netsec", "url": "https://www.reddit.com/r/netsec/", "description": "网络安全新闻和讨论社区"},
          {"name": "SecTools", "url": "https://sectools.org/", "description": "网络安全工具和资源大全"},
          {"name": "Hack This Site", "url": "https://www.hackthissite.org/", "description": "在线黑客挑战平台"},
          {"name": "SecurityTrails", "url": "https://securitytrails.com/", "description": "互联网安全数据查询平台"},
          {"name": "Seebug", "url": "https://www.seebug.org/", "description": "安全研究社区"},
          {"name": "HackTricks", "url": "https://book.hacktricks.xyz/", "description": "安全技巧和漏洞利用指南"},
          {"name": "Archive.org", "url": "https://archive.org/", "description": "互联网存档"},
          {"name": "OWASP ZAP", "url": "https://www.zaproxy.org/", "description": "Web应用安全测试工具"},
          {"name": "FreeBuf", "url": "https://www.freebuf.com/", "description": "安全新闻和攻击技巧"},
          {"name": "雨苁", "url": "https://www.ddosi.org/", "description": "各类hk资源分享"},
          

        ]
      },
      {
      "name": "抓包工具",
      "icon": "Tools",
      "emoji": "⛓",
      "links": [
        {"name": "Wireshark", "url": "https://www.wireshark.org/", "description": "网络协议分析工具"},
        {"name": "Charles", "url": "https://www.charlesproxy.com/", "description": "网络调试工具"},
        {"name": "Fiddler", "url": "https://www.telerik.com/fiddler", "description": "网络调试工具"},
        {"name": "HTTP Debugger", "url": "https://www.httpdebugger.com/", "description": "网络调试工具"},
      


      ]
    }
      
      
]